import React from "react";
import { useTranslated, type Locale } from '@i18next/utils';
import { Swiper, SwiperSlide, type SwiperProps } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import type { SwiperModule } from "swiper/types";
import Picture from "@react/widget/Picture";
import Button from "@react/widget/Button";
import { H1 } from "@react/widget/Headline";
import clsx from "clsx";

interface Props extends SwiperProps {
  locale: Locale;
  // data: Array<CaseStudyEntry>;
}

const HPHeroCarouselVisiual: React.FC<Props> = ({ locale, autoHeight = false, spaceBetween = 0, loop = true, ...props }) => {
  const { tr } = useTranslated(locale);
  const modules: Array<SwiperModule> = [];
  const data:Array<React.ReactNode> = [
    (
      <section className="py-0 h-full bg-inherit relative">
        <div className="container mx-auto pt-0 md:pt-0 pb-0 md:pb-0 h-full">
          <div className="flex flex-col md:flex-row gap-6">
            <div className="hero-left w-full sm:w-4/5 [&_button]:max-sm:w-full [&_button]:max-sm:justify-center [&_h2]:mt-1 [&_h2]:md:mt-4 [&_p]:md:text-xl [&_p]:mt-3 [&_p]:md:mt-6 [&_.desc]:md:text-xl [&_.desc]:mt-3 [&_.desc]:md:mt-6 pb-12 md:pb-20 md:w-1/2">
              <div className="pt-12 md:pt-20">
                <H1>Beyond Secure.<br /> AvePoint Secure.</H1>
                <p className="mt-4 md:mt-8">Traditional data security solutions fall short in the age of AI. AvePoint integrates data security, governance, and resilience to provide complete protection.</p>
                <Button style="primary" size="lg" className="mt-8 mb-6 md:mb-0" href={tr("/beyond-secure")}>Learn More</Button>
              </div>
            </div>
          </div>
        </div>
        <div className={clsx("background inset-0 z-[-1] flex justify-end absolute md:block bg-gray-100")}>
        <Picture className={clsx("w-full h-full object-cover object-[40%_center] sm:object-[55%_center] md:object-[70%_center]  lg:object-center")} src="https://assets.avepoint.com/upload/images/home/Illustrations/home-hero-beyond-secure-2024.webp" width={2560} height={700} sources={[
            {media: "(max-width: 479px)", srcSet: [{width: 465, height: 155, position: "left"}], width: 465, height: 155},
            {media: "(max-width: 767px)", srcSet: [{width: 760, height: 250, position: "right", quality:90}], width: 760, height: 250},
            {media: "(max-width: 1439px)", srcSet: [{width: 1440, height: 475, position: "right"}], width: 1440, height: 475},
            {media: "(min-width: 1440px)", srcSet: [{width:2560, height:500, quality:80},{width:1920, height:500, quality:80}]}
        ]}/>
        </div>
      </section>
    )
  ];

  if(data.length < 2)
    props.pagination = false;
  if (!!props.pagination) {
    props.pagination = Object.assign({
      clickable: true
    }, (typeof props.pagination === "boolean") ? {}: props.pagination);
    modules.push(Pagination);
  }
  if (!!props.autoplay) {
    props.autoplay = Object.assign({
      delay: 3000,
      disableOnInteraction:	true,
      pauseOnMouseEnter: true,
      reverseDirection: false,
      stopOnLastSlide: false,
      waitForTransition: true,
    }, (typeof props.autoplay === "object") ? props.autoplay: {});
    modules.push(Autoplay);
  }
  if (!!props.navigation)
    modules.push(Navigation);

  return (
    <Swiper
      className={""}
      autoHeight={autoHeight}
      spaceBetween={spaceBetween}
      loop={loop}
      modules={modules}
      {...props}
    >
      {data.map((d, i) => <SwiperSlide key={`HPHeroCarouselVisiual-${i}`}>{d}</SwiperSlide>)}
    </Swiper>
  );
};
export default HPHeroCarouselVisiual;